import React from "react"
import { useEffect } from "react"

import { Link } from "gatsby"

import { blue } from "@material-ui/core/colors"

import { makeStyles, jssPreset } from "@material-ui/core/styles"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

// @material-ui/icons
// import Button from "@material-ui/core/Button"
// import Button from "components/CustomButtons/Button.js"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"
// import Paper from "@material-ui/core/Paper"
import Badge from "@material-ui/core/Badge"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListText from "@material-ui/core/ListItemText"
import Grid from "@material-ui/core/Grid"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
// core components
import useFirebase from "components/useFirebase"
import Moment from "react-moment"

import AcUnitSharpIcon from "@material-ui/icons/AcUnitSharp"
import Chip from "@material-ui/core/Chip"
import Avatar from "@material-ui/core/Avatar"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansion: {
    backgroundColor: "lightgray",
  },
}))

function MobileGroup({ ...props }) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const groups = props.input

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={classes.root}>
      {[]
        .concat(groups)
        .sort((a, b) => a.groupStart - b.groupStart)
        .filter(group => {
          return props.groupType ? group.groupType === props.groupType : true
        })
        .filter(group => {
          return props.groupDays ? group.groupDays === props.groupDays : true
        })
        .filter(group => {
          return props.groupName
            ? group.groupName.includes(props.groupName)
            : true
        })
        .map(group => (
          <ExpansionPanel
            className={{ expanded: classes.expansion }}
            expanded={expanded === group.groupName + group.groupFee}
            onChange={handleChange(group.groupName + group.groupFee)}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Grid container justify="space-between">
                <Typography
                  className={classes.heading}
                  inline="true"
                  align="left"
                >
                  <Moment format="M/DD">{group.groupStart}</Moment> -{" "}
                  <Moment format="M/DD">{group.groupEnd}</Moment>
                </Typography>
                <Chip
                  size="small"
                  label={group.groupOpen}
                  color={group.groupOpen === "已額滿" ? "default" : "secondary"}
                />
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansion}>
              <Typography>
                <List dense="true">
                  <ListItem>
                    團期: <Moment format="M/DD">{group.groupStart}</Moment> -{" "}
                    <Moment format="M/DD">{group.groupEnd}</Moment>
                  </ListItem>
                  <ListItem>團費: ${group.groupFee}</ListItem>
                  <ListItem>團名/代號: {group.groupName}</ListItem>
                  <ListItem>導遊: {group.guides}</ListItem>
                  <ListItem>空位: {group.groupOpen}</ListItem>
                </List>
              </Typography>
            </ExpansionPanelDetails>
            <ExpansionPanelActions className={classes.expansion}>
              <Link to="Checkout">
                <Button
                  size="lg"
                  fullWidth="true"
                  color="primary"
                  variant={
                    group.groupOpen === "已額滿" ? "outlined" : "contained"
                  }
                >
                  {group.groupOpen === "已額滿" ? "我要候補" : "我要報名"}
                </Button>
              </Link>
            </ExpansionPanelActions>
          </ExpansionPanel>
        ))}
    </div>
  )
}

function Groups({ ...props }) {
  const firebase = useFirebase()
  const [groups, setGroups] = React.useState([])

  useEffect(() => {
    if (!firebase) return
    firebase
      .database()
      .ref("POCtest/groups/metadata")
      .once("value")
      .then(snapshot => {
        setGroups(snapshot.val())
      })
  }, [firebase])

  return (
    <div>
      <Hidden smUp>
        <MobileGroup input={groups} {...props} />
      </Hidden>

      <Hidden smDown>
        <TableContainer autoWidth={false}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">日期</TableCell>
                <TableCell>費用(加幣)</TableCell>
                <TableCell>團名/代號</TableCell>
                <Hidden smDown>
                  <TableCell>導遊</TableCell>
                </Hidden>
                <TableCell>空位</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[]
                .concat(groups)
                .sort((a, b) => a.groupStart - b.groupStart)
                .filter(group => {
                  return props.groupType
                    ? group.groupType === props.groupType
                    : true
                })
                .filter(group => {
                  return props.groupDays
                    ? group.groupDays === props.groupDays
                    : true
                })
                .filter(group => {
                  return props.groupName
                    ? group.groupName.includes(props.groupName)
                    : true
                })
                .map(group => (
                  <TableRow key={group.id + group.groupFee}>
                    <TableCell align="center" component="th" scope="row">
                      <Moment format="M/DD">{group.groupStart}</Moment> -{" "}
                      <Moment format="M/DD">{group.groupEnd}</Moment>
                    </TableCell>
                    <TableCell>${group.groupFee}</TableCell>
                    <TableCell>{group.groupName}</TableCell>
                    <Hidden smDown>
                      <TableCell>{group.guides}</TableCell>
                    </Hidden>
                    <TableCell>{group.groupOpen}</TableCell>
                    <TableCell>
                      <Link to="Checkout">
                        <Button
                          variant="contained"
                          style={{ fontSize: 16 }}
                          color={
                            group.groupOpen === "已額滿" ? "default" : "primary"
                          }
                        >
                          {group.groupOpen === "已額滿" ? "候補" : "報名"}
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>
    </div>
  )
}

export default React.memo(Groups)
